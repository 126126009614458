// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kopvillkor-js": () => import("./../../../src/pages/kopvillkor.js" /* webpackChunkName: "component---src-pages-kopvillkor-js" */),
  "component---src-pages-runt-mustigt-js": () => import("./../../../src/pages/runt-mustigt.js" /* webpackChunkName: "component---src-pages-runt-mustigt-js" */),
  "component---src-pages-smakpaket-js": () => import("./../../../src/pages/smakpaket.js" /* webpackChunkName: "component---src-pages-smakpaket-js" */),
  "component---src-pages-vanliga-fragor-js": () => import("./../../../src/pages/vanliga-fragor.js" /* webpackChunkName: "component---src-pages-vanliga-fragor-js" */)
}

